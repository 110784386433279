const Pagination = ({ pages, currentPage, PageFunction }) => {
    const visiblePages = 5;

    const getPagesToDisplay = () => {
        const pagesArray = [];

        if (pages <= visiblePages + 2) {
            for (let i = 1; i <= pages; i++) {
                pagesArray.push(i);
            }
        } else {
            if (currentPage <= visiblePages) {
                for (let i = 1; i <= visiblePages; i++) {
                    pagesArray.push(i);
                }
                pagesArray.push('...');
                pagesArray.push(pages);
            } else if (currentPage > pages - visiblePages) {
                pagesArray.push(1);
                pagesArray.push('...');
                for (let i = pages - visiblePages + 1; i <= pages; i++) {
                    pagesArray.push(i);
                }
            } else {
                pagesArray.push(1);
                pagesArray.push('...');
                for (let i = currentPage - 2; i <= currentPage + 2; i++) {
                    pagesArray.push(i);
                }
                pagesArray.push('...');
                pagesArray.push(pages);
            }
        }

        return pagesArray;
    };

    const pagesToDisplay = getPagesToDisplay();

    return (
        <div className="pagination">
            <div onClick={() => currentPage > 1 && PageFunction(currentPage - 1)} className="page-name">
                <h2>&lt;</h2>
            </div>
            {pagesToDisplay.map((page, i) => (
                <div
                    key={i}
                    onClick={() => typeof page === 'number' && PageFunction(page)}
                    className={`page-name ${currentPage === page ? 'active' : ''}`}  // Aktiv sahifaga rang berish
                >
                    <h2>{page}</h2>
                </div>
            ))}
            <div onClick={() => currentPage < pages && PageFunction(currentPage + 1)} className="page-name">
                <h2>&gt;</h2>
            </div>
        </div>
    );
};


export default Pagination;
