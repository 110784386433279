import React, { useState, useRef } from 'react';
import { IoSearch } from 'react-icons/io5';
import { MdOutlineDateRange } from 'react-icons/md';
import { RiCloseFill } from 'react-icons/ri';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Table from '../tableProducts/Table';
import productApi from '../../api/productApi';

export default function Products() {
  const [showModal, setShowModal] = useState(false);
  const [image, setImage] = useState(null);
  const [name, setName] = useState('');
  const [shortDescRu, setShortDescRu] = useState('');
  const [shortDescUz, setShortDescUz] = useState('');
  const [descriptionRu, setDescriptionRu] = useState('');
  const [descriptionUz, setDescriptionUz] = useState('');
  const [searchTerm, setSearchTerm] = useState('');  // Add search term state
  const tableRef = useRef(null);

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };

  const resetForm = () => {
    setName('');
    setShortDescRu('');
    setShortDescUz('');
    setDescriptionRu('');
    setDescriptionUz('');
    setImage(null);
  };

  const handleSubmit = async (e) => {
  e.preventDefault();
  const formData = new FormData();
  formData.append('name', name);
  formData.append('description_min_ru', shortDescRu);
  formData.append('description_min_uz', shortDescUz);
  formData.append('description_ru', descriptionRu);
  formData.append('description_uz', descriptionUz);

  if (image) {
    formData.append('image', image);
  }

  // Tekshirish qismi: barcha maydonlar to'ldirilganligini tekshiradi
  if (
    name.trim() !== "" &&
    shortDescRu.trim() !== "" &&
    shortDescUz.trim() !== "" &&
    descriptionRu.trim() !== "" &&
    descriptionUz.trim() !== "" &&
    image
  ) {
    // Agar barcha maydonlar to'ldirilgan bo'lsa, keyingi amallar bajariladi
    try {
      await productApi.postProductApi(formData);
      closeModal();
      resetForm();
      if (tableRef.current) {
        tableRef.current.refreshTable();
      }
    } catch (error) {
      console.error('Error creating product:', error);
      alert('Servisda hatolik bor qayta urinib ko`ring');

    }
  } else {
    // Agar maydonlar to'ldirilmagan bo'lsa, alert chiqariladi
    alert('Bo`sh joylarni to`ldiring');
  }
};


  return (
    <div className='Products'>
      <div className="container">
        <div className="search-section">
          <div className="search-box">
            <IoSearch className="icon" />
            <input
              type="text"
              placeholder="Поиск"
              className="search-input"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}  // Update search term state
            />
          </div>
          <div className="select-period">
            <input type="text" placeholder="Выберите период" className="date-input" />
            <div className="dropdown-icon">
              <MdOutlineDateRange className="icon" />
            </div>
          </div>
        </div>
        <div className="button-section">
          <button onClick={openModal} className="add-button">
            <span className="button-icon">+</span> Добавить
          </button>
        </div>
      </div>
      <div className='table_products'>
        <Table ref={tableRef} searchTerm={searchTerm} />  {/* Pass searchTerm to the Table component */}
      </div>
      {showModal && (
        <div className='modal'>
          <div className='modal-content'>
            <span className='close' onClick={closeModal}><RiCloseFill /></span>
            <form className='modal-form' onSubmit={handleSubmit}>
              <div className='form-group'>
                <div className='upload-button'>
                  <label htmlFor='imageUpload'>
                    {image ? <img className='imageUpload_img' src={URL.createObjectURL(image)} alt='Upload Preview' /> : <div>img push</div>}
                  </label>
                  <input
                    type='file'
                    id='imageUpload'
                    accept='image/*'
                    onChange={handleImageChange}
                    style={{ display: 'none' }}
                  />
                </div>
                <input
                  type='text'
                  placeholder='Имя'
                  className='input-field'
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <p>Короткое Описание ru</p>
                <CKEditor
                  editor={ClassicEditor}
                  data={shortDescRu}
                  onChange={(event, editor) => setShortDescRu(editor.getData())}
                />
                <p>Короткое Описание uz</p>
                <CKEditor
                  editor={ClassicEditor}
                  data={shortDescUz}
                  onChange={(event, editor) => setShortDescUz(editor.getData())}
                />
                <p>Описание ru</p>
                <CKEditor
                  editor={ClassicEditor}
                  data={descriptionRu}
                  onChange={(event, editor) => setDescriptionRu(editor.getData())}
                />
                <p>Описание uz</p>
                <CKEditor
                  editor={ClassicEditor}
                  data={descriptionUz}
                  onChange={(event, editor) => setDescriptionUz(editor.getData())}
                />
                <button type='submit' className='submit-button'>push</button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}
