import React, { useEffect, useState } from 'react';
import { FiMoreVertical } from 'react-icons/fi';
import QueriesApi from '../../api/queriesApi';
import Modal from '../mobdal/Modal';
import Pagination from './pagination/Pagination'

export default function Table() {
    const [queries, setQueries] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [pages, setPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const itemsPerPage = 10;

    useEffect(() => {
        fetchQueries((currentPage - 1) * itemsPerPage);
    }, [currentPage]);

    const fetchQueries = async (offset) => {
        setLoading(true);
        try {
            const response = await QueriesApi.GetQueriesApi(offset, itemsPerPage);
            console.log('API response:', response.Client);

            if (response && response.Client && Array.isArray(response.Client)) {
                setQueries(response.Client);
                const totalPages = Math.ceil(response.count / itemsPerPage);
                setPages(totalPages);
            } else {
                setQueries([]);
                setPages(1);
            }
        } catch (error) {
            console.error('Error fetching queries:', error);
            setQueries([]);
            setPages(1);
        }
        setLoading(false);
    };

    const handleMoreClick = (row) => {
        setSelectedRow(row);
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
        setSelectedRow(null);
    };

    const PageFunction = (pageId) => {
        setCurrentPage(pageId);
    };
    const Delete = (usersId) => {
        console.log(usersId); // Log the user ID for debugging
        QueriesApi.DeleteApi(usersId)
            .then((res) => {
                console.log('Delete response:', res); // Log the response
                closeModal(); // Close modal after deletion
                fetchQueries((currentPage - 1) * itemsPerPage);
            })
            .catch((err) => {
                console.error('Error during deletion:', err); // Handle deletion error
            });
    };


    const renderSkeletonRows = () => {
        return Array.from({ length: itemsPerPage }).map((_, index) => (
            <tr key={index}>
                <td><div className="skeleton skeleton-text"></div></td>
                <td><div className="skeleton skeleton-text"></div></td>
                <td><div className="skeleton skeleton-text"></div></td>
                <td><div className="skeleton skeleton-text"></div></td>
                <td><div className="skeleton skeleton-text"></div></td>
                <td></td>
            </tr>
        ));
    };

    return (
        <div className="table-container">
            <table>
                <thead>
                    <tr>
                        <th>№</th>
                        <th>Ф.И.О</th>
                        <th>Дата</th>
                        <th>Контакты</th>
                        <th>Регион</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        loading
                            ? renderSkeletonRows()
                            : queries.length > 0 ?
                                queries.map((query, index) => (
                                    <tr key={query.id}>
                                        <td>{index + 1 + (currentPage - 1) * itemsPerPage}</td>
                                        <td>{query.name}</td>
                                        <td>{new Date(query.created_at).toLocaleDateString()}</td>
                                        <td>{query.phone}</td>
                                        <td>{query.region}</td>
                                        <td style={{ width: "40px", textAlign: "center" }}>
                                            <button className="more-button" onClick={() => handleMoreClick(query)}>
                                                <FiMoreVertical />
                                            </button>
                                        </td>
                                    </tr>
                                ))
                                : <tr><td colSpan="6">No data available</td></tr>
                    }
                </tbody>
            </table>

            <Pagination pages={pages} currentPage={currentPage} PageFunction={PageFunction} />

            {showModal && selectedRow && (
                <Modal onClose={closeModal}>
                    <div className='modal-content-users'>
                        <p><h3>Details for:</h3>{selectedRow.name}</p>
                        <p><h3>Details for:</h3>{new Date(selectedRow.created_at).toLocaleDateString()}</p>
                        <p><h3>Details for:</h3>{selectedRow.phone}</p>
                        <p><h3>Details for:</h3>{selectedRow.region}</p>


                        <button className="delete-button" onClick={() => { Delete(selectedRow.id) }}>
                            Delete
                        </button>
                   </div>
                </Modal>
            )}

        </div>
    );
}