import client, { BaseUrl } from "./clientApi";

class QueriesApi {
    // Method to fetch queries with token validation
    static async GetQueriesApi(offset, limit) {
        const tokenData = localStorage.getItem("userToken"); // Moved inside the method

        if (!tokenData) {
            throw new Error("Authorization token is missing.");
        }

        const endPoint = `/client?offset=${offset}&limit=${limit}`;

        try {
            const response = await client.get(BaseUrl + endPoint, {
                headers: {
                    'Authorization': `Bearer ${tokenData}`,
                },
            });
            return response.data;
        } catch (err) {
            console.error('Error during API call:', err);
            throw err;
        }
    }

    // Method to delete a query using user ID
    static async DeleteApi(usersId) {
        const tokenData = localStorage.getItem("userToken"); // Fetching token inside the method

        if (!tokenData) {
            throw new Error("Authorization token is missing.");
        }

        const endPoint = `/client/${usersId}`; // Construct endpoint for deletion

        try {
            const response = await client.delete(BaseUrl + endPoint, {
                headers: {
                    'Authorization': `Bearer ${tokenData}`,
                },
            });
            return response.data; // Return response
        } catch (err) {
            console.error('Error deleting user:', err);
            throw err;
        }
    }
}

export default QueriesApi;
