import React, { useState } from 'react';
import Modal from 'react-modal';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const EditModal = ({ isOpen, onRequestClose, product, handleSubmit }) => {
    const [image, setImage] = useState(null);
    const [name, setName] = useState(product?.name || '');
    const [shortDescRu, setShortDescRu] = useState(product?.description_min_ru || '');
    const [shortDescUz, setShortDescUz] = useState(product?.description_min_uz || '');
    const [descriptionRu, setDescriptionRu] = useState(product?.description_ru || '');
    const [descriptionUz, setDescriptionUz] = useState(product?.description_uz || '');

    const handleImageChange = (e) => {
        setImage(e.target.files[0]);
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('name', name);
        formData.append('description_min_ru', shortDescRu);
        formData.append('description_min_uz', shortDescUz);
        formData.append('description_ru', descriptionRu);
        formData.append('description_uz', descriptionUz);

        if (image) {
            formData.append('image', image);
        }

        handleSubmit(formData, product.id);
        onRequestClose();
        setImage('')
        setName('')
        setShortDescRu('')
        setShortDescUz('')
        setDescriptionRu('')
        setDescriptionUz('')
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="Edit Product Modal"
            style={{ content: { padding: '0', border: 'none', borderRadius: '10px' } }}
        >
            <form className='modal-form' onSubmit={handleFormSubmit}>
                <div className='form-group'>
                    <div className='upload-button'>
                        <label htmlFor='imageUpload'>
                            {image || product?.image ? (
                                <img
                                    className='imageUpload_img'
                                    src={image ? URL.createObjectURL(image) : product.image}
                                    alt='Upload Preview'
                                />
                            ) : (
                                <div>Upload Image</div>
                            )}
                        </label>
                        <input
                            type='file'
                            id='imageUpload'
                            onChange={handleImageChange}
                        />
                    </div>
                </div>
                <div className='form-group'>
                    <label htmlFor='name'>Название:</label>
                    <input
                        type='text'
                        id='name'
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                    />
                </div>
                <div className='form-group'>
                    <label>Короткое описание:</label>
                    <CKEditor
                        editor={ClassicEditor}
                        data={shortDescRu}
                        onChange={(event, editor) => {
                            setShortDescRu(editor.getData());
                        }}
                    />
                </div>
                <div className='form-group'>
                    <label>Qisqacha Tavsif:</label>
                    <CKEditor
                        editor={ClassicEditor}
                        data={shortDescUz}
                        onChange={(event, editor) => {
                            setShortDescUz(editor.getData());
                        }}
                    />
                </div>
                <div className='form-group'>
                    <label>Описание:</label>
                    <CKEditor
                        editor={ClassicEditor}
                        data={descriptionRu}
                        onChange={(event, editor) => {
                            setDescriptionRu(editor.getData());
                        }}
                    />
                </div>
                <div className='form-group'>
                    <label>Tavsif:</label>
                    <CKEditor
                        editor={ClassicEditor}
                        data={descriptionUz}
                        onChange={(event, editor) => {
                            setDescriptionUz(editor.getData());
                        }}
                    />
                </div>
                <button type='submit'>Update</button>
            </form>
        </Modal>
    );
};

export default EditModal;
