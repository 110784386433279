import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from './pages/login/Login';
import Products from './components/products/Products';
import UserRequests from './components/userRequests/UserRequests'
import './styles/Styles.scss';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<App />} >
          <Route path="/" element={<UserRequests />} />
          <Route path='/products' element={<Products />} />
        </Route>
      </Routes>
    </BrowserRouter>
);

reportWebVitals();
