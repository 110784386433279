import client, { BaseUrl } from "./clientApi";

class LoginAdmin {
    LoginAdminApi(props) {
        const endPoint = "/login";

        const postLogin = client
            .post(BaseUrl + endPoint, {
                username: props.username,
                password: props.password
            })
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err;
            });

        return postLogin;
    }
    //   getAllUser()

}

export default new LoginAdmin();