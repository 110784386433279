import React, { useEffect } from 'react';
import { useNavigate, useLocation, Outlet } from 'react-router-dom';
import { IoLogOutOutline } from "react-icons/io5";
import { CiUser } from "react-icons/ci";
import { FaUserShield } from "react-icons/fa6";

function App() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const userToken = localStorage.getItem('userToken');
    const tokenTimestamp = localStorage.getItem('tokenTimestamp');

    // Check if token exists
    if (!userToken) {
      navigate('/login');
      return;
    }

    // Check if the token has expired (24 hours = 86400000 milliseconds)
    const now = new Date().getTime();
    if (now - tokenTimestamp > 86400000) {
      localStorage.removeItem('userToken');
      localStorage.removeItem('tokenTimestamp');
      navigate('/login');
    }
  }, [navigate]);

  function Products() {
    navigate('/');
  }

  function Users() {
    navigate('/products');
  }

  function DeleteAkk() {
    localStorage.removeItem('userToken');
    localStorage.removeItem('tokenTimestamp');
    navigate('/login');
  }

  return (
    <div className='App_holding'>
      <div className='App_holding__left'>
        <div className='App_holding__left__management'>
          <div
            onClick={Products}
            style={{ background: location.pathname === '/' ? 'rgba(23, 37, 90, 1)' : '' }}
          >
            <CiUser />
          </div>
          <div
            onClick={Users}
            style={{ background: location.pathname === '/products' ? 'rgba(23, 37, 90, 1)' : '' }}
          >
            <FaUserShield />
          </div>
        </div>
        <div onClick={DeleteAkk} className='App_holding__left__delete'>
          <IoLogOutOutline />
        </div>
      </div>
      <div className='App_holding__right'>
        <Outlet />
      </div>
    </div>
  );
}

export default App;
