import client, { BaseUrl } from './clientApi';

class ProductApi {
    // Fetch products with pagination
    getProductApi(language, offset = 0, limit = 10) {
        const endPoint = `/product_${language}?offset=${offset}&limit=${limit}`;
        return client.get(BaseUrl + endPoint)
            .then((res) => res.data)
            .catch((err) => {
                console.error('Error fetching products:', err);
                throw err;
            });
    }

    // Create a new product
    postProductApi(formData) {
        const endPoint = '/product';
        const token = this.getToken();


        return client.post(BaseUrl + endPoint, formData, {
            headers: {
                'Authorization': `Bearer ${token}`, // Fixed this
                'Content-Type': 'multipart/form-data',
            },
        })
            .then((res) => res.data)
            .catch((err) => {
                console.error('Error posting product:', err);
                throw err;
            });
    }

    // Update an existing product
    updateProductApi(formData, productId) {
        const endPoint = `/product/${productId}`; // Added quotes around URL
        const token = this.getToken();

        return client.patch(BaseUrl + endPoint, formData, {
            headers: {
                'Authorization': `Bearer ${token}`, // Fixed this
                'Content-Type': 'multipart/form-data',
            },
        })
            .then((res) => res.data)
            .catch((err) => {
                console.error('Error updating product:', err);
                throw err;
            });
    }

    // Delete a product
    deleteProductApi(id) {
        const endPoint = `/product/${id}`; // Added quotes around URL
        const token = this.getToken();

        return client.delete(BaseUrl + endPoint, {
            headers: {
                'Authorization': `Bearer ${token}`, // Fixed this
            },
        })
            .then((res) => res.data)
            .catch((err) => {
                console.error('Error deleting product:', err);
                throw err;
            });
    }

    // Get the token from localStorage
    getToken() {
        const userToken = localStorage.getItem('userToken');
        if (userToken) {
            try {
                const parsedToken = userToken;
                console.log('Parsed Token:', userToken); // Debugging line
                return userToken || '';
            } catch (error) {
                console.error('Error parsing token:', error);
                return '';
            }
        }
        return '';
    }

}

export default new ProductApi();
