import axios from "axios";

// export const BaseUrl = "https://prozed-uz.onrender.com";
export const BaseUrl = "https://api.prozed.uz";

const client = axios.create({
    baseURL: BaseUrl,
    timeout: 1000, 
});

export default client;
