import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LoginAdmin from '../../api/LoginAdmin';
import CircularProgress from '@mui/material/CircularProgress';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const login = () => {
    // Reset error message
    setErrorMessage('');
    setLoading(true);

    // Check if username and password are provided
    if (username !== '' && password !== '') {
      // Call API for login
      LoginAdmin.LoginAdminApi({ username, password })
        .then(response => {
          setLoading(false); // Stop loading spinner

          if (response.message === "Request failed with status code 401") {
            setErrorMessage("Login yoki parol noto'g'ri. Iltimos, qayta kiriting.");
          } else if (response.message === "timeout of 1000ms exceeded") {
            setErrorMessage("Serverda xatolik yuz berdi. Iltimos, keyinroq urinib ko'ring.");
          } else if (response?.data) {
            // If login is successful, save token and navigate
            localStorage.setItem("tokenTimestamp", new Date().getTime());
            localStorage.setItem("userToken", response.data);
            navigate("/"); // Navigate to the home page or dashboard
          } else {
            setErrorMessage("Login amalga oshmadi, iltimos qaytadan urinib ko'ring.");
          }
        })
        .catch(error => {
          setLoading(false); // Stop loading spinner
          setErrorMessage("Xatolik yuz berdi. Iltimos, qayta urinib ko'ring.");
        });
    } else {
      setLoading(false);
      setErrorMessage("Login va parolni kiriting.");
    }
  };

  return (
    <div className="login">
      <div className="login-container">
        <div className="login_boder">
          <div className="logo">
            <img src="./images/login/login_logo.png" alt="PRO-ZED Logo" />
          </div>
          <div className="login-form">
            <h2>Вход</h2>
            {errorMessage && (
              <div style={{ color: 'red', marginTop: '10px' }}>
                {errorMessage}
              </div>
            )}
            <input
              type="text"
              placeholder="Логин"
              onInput={(val) => setUsername(val.target.value)}
              value={username}
            />
            <input
              type="password"
              placeholder="Пароль"
              onInput={(val) => setPassword(val.target.value)}
              value={password}
            />
            <button onClick={login} disabled={loading}>
              {loading ? <CircularProgress size={24} /> : "Войти"}
            </button>

          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
