import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { BsThreeDotsVertical } from "react-icons/bs";
import { RiDeleteBinLine, RiEdit2Fill } from "react-icons/ri";
import productApi from '../../api/productApi';
import EditModal from './editModal/EditModal';
import Pagination from './pagination/Pagination';  // Import paginatsiya

const Table = forwardRef(({ searchTerm }, ref) => {
    const [language, setLanguage] = useState('uz');
    const [products, setProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [pages, setPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [dropdownVisible, setDropdownVisible] = useState(null);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [loading, setLoading] = useState(true); // Loading state
    const [ModalDescription, setModalDescription] = useState('')
    const itemsPerPage = 10;

    useEffect(() => {
        fetchProducts((currentPage - 1) * itemsPerPage);
    }, [language, currentPage]);

    useEffect(() => {
        if (searchTerm) {
            setFilteredProducts(products.filter(product => product.name.toLowerCase().includes(searchTerm.toLowerCase())));
        } else {
            setFilteredProducts(products);
        }
    }, [searchTerm, products]);

    useImperativeHandle(ref, () => ({
        refreshTable() {
            fetchProducts((currentPage - 1) * itemsPerPage);
        }
    }));

    const fetchProducts = async (offset = 0) => {
        setLoading(true); // Set loading to true before fetching
        try {
            const response = await productApi.getProductApi(language, offset, itemsPerPage);
            if (response && response.count) {
                setProducts(language === 'uz' ? response.Product_uz : response.Product_ru);
                const totalPages = Math.ceil(response.count / itemsPerPage);
                setPages(totalPages);
            } else {
                setProducts([]);
                setPages(1);
            }
        } catch (error) {
            console.error('Error fetching products:', error);
            setProducts([]);
            setPages(1);
        }
        setLoading(false); // Set loading to false after fetching
    };

    const PageFunction = (pageId) => {
        setCurrentPage(pageId);
    };

    const handleLanguageChange = (e) => {
        setLanguage(e.target.value);
        setCurrentPage(1);
    };

    const handleDotsClick = (description) => {
        setModalDescription(description)
    };

    const handleDropdownClick = (productId) => {
        setDropdownVisible(dropdownVisible === productId ? null : productId);
    };

    const handleEditClick = (product) => {
        setSelectedProduct(product);
        setEditModalOpen(true);
        setDropdownVisible(null);
    };

    const handleDeleteClick = async (product) => {
        try {
            await productApi.deleteProductApi(product.id);
            fetchProducts((currentPage - 1) * itemsPerPage);
        } catch (error) {
            console.error('Failed to delete the product:', error);
        }
        setDropdownVisible(null);
    };

    const handleProductUpdate = async (formData, productId) => {
        try {
            await productApi.updateProductApi(formData, productId);
            setEditModalOpen(false);
            fetchProducts((currentPage - 1) * itemsPerPage);
        } catch (error) {
            console.error('Error updating product:', error);
        }
    };

    const truncateText = (text, length = 50) => {
        if (!text) return '';
        return text.length > length ? text.substring(0, length) + '...' : text;
    };

    const renderSkeletonRows = () => {
        // Displaying 10 skeleton rows matching table structure
        return Array.from({ length: itemsPerPage }).map((_, index) => (
            <tr key={index}>
                <td><div className="skeleton skeleton-text"></div></td>
                <td><div className="skeleton skeleton-image"></div></td>
                <td><div className="skeleton skeleton-text"></div></td>
                <td><div className="skeleton skeleton-text"></div></td>
                <td><div className="skeleton skeleton-text"></div></td>
                <td></td>
            </tr>
        ));
    };

    return (
        <div className="table-container">
            <div className="language-selector">
                <div>
                    <span>{`Sahifa ${currentPage} / ${pages}`}</span>
                </div>
                <select className='select' onChange={handleLanguageChange} value={language}>
                    <option value="uz">O'zbek</option>
                    <option value="ru">Русский</option>
                </select>
            </div>

            <table>
                <thead>
                    <tr>
                        <th>№</th>
                        <th>ФОТО</th>
                        <th>Имя</th>
                        <th>Короткое Описание</th>
                        <th>Описание</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        loading
                            ? renderSkeletonRows()
                            : filteredProducts.length > 0 ?
                                filteredProducts.map((product, index) => (
                                    <tr key={product.id}>
                                        <td>{index + 1 + (currentPage - 1) * itemsPerPage}</td>
                                        <td><img src={product.image} alt="product" /></td>
                                        <td>{product.name}</td>
                                        <td onClick={() => handleDotsClick(language === 'ru' ? product.description_min_ru : product.description_min_uz)} style={{cursor:"pointer"}}>
                                            <span dangerouslySetInnerHTML={{ __html: truncateText(language === 'ru' ? product.description_min_ru : product.description_min_uz) }}></span>
                                        </td>
                                        <td onClick={() => handleDotsClick(language === 'ru' ? product.description_ru : product.description_uz)} style={{cursor:"pointer"}}>
                                            <span dangerouslySetInnerHTML={{ __html: truncateText(language === 'ru' ? product.description_ru : product.description_uz) }}></span>
                                        </td>
                                        <td style={{ width: "25px", textAlign: "center", position: "relative" }}>
                                            <div className="dots-menu">
                                                <button onClick={() => handleDropdownClick(product.id)}>
                                                    <BsThreeDotsVertical />
                                                </button>
                                                {dropdownVisible === product.id && (
                                                    <div style={{ position: "absolute", right: "25px", bottom: -5, backgroundColor: "white", borderRadius: "8px", padding: "6px", boxShadow: "1px 1px 10px 3px rgba(61, 61, 61, 0.811)", zIndex: "15" }}>
                                                        <button onClick={() => handleEditClick(product)} style={{ display: "flex" }}><RiEdit2Fill /> Tahrirlash</button>
                                                        <button onClick={() => handleDeleteClick(product)} style={{ display: "flex" }}><RiDeleteBinLine /> Ochirish</button>
                                                    </div>
                                                )}
                                            </div>
                                        </td>
                                    </tr>
                                ))
                                : <tr><td colSpan="6" className="no-products">No products found</td></tr>
                    }
                </tbody>

            </table>

            {/* Pagination */}
            <Pagination pages={pages} currentPage={currentPage} PageFunction={PageFunction} />

            {/* Edit modal */}
            <div style={{ zIndex: 5}}>
                {selectedProduct && (
                    <EditModal
                        isOpen={editModalOpen}
                        onRequestClose={() => setEditModalOpen(false)}
                        product={selectedProduct}
                        handleSubmit={handleProductUpdate}
                    />
                )}
            </div>
            {
                ModalDescription && (
                    <div
                        onClick={() => setModalDescription('')} // Close modal when clicking outside the content
                        style={{
                            zIndex: 5,
                            position: "fixed",
                            top: 0,
                            left: 0,
                            width: "100vw",
                            height: "100vh",
                            backgroundColor: "rgba(0, 0, 0, 0.5)", // Dimmed background
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <div
                            onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the modal
                            style={{
                                position: "relative",
                                backgroundColor: "white",
                                borderRadius: "10px",
                                padding: "50px",
                                maxWidth: "600px",
                                boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
                            }}
                        >
                            <button
                                onClick={() => setModalDescription('')} // Close modal on X button click
                                style={{
                                    position: "absolute",
                                    top: "10px",
                                    right: "10px",
                                    backgroundColor: "transparent",
                                    border: "none",
                                    fontSize: "18px",
                                    cursor: "pointer",
                                }}
                            >
                                &times;
                            </button>
                            <div dangerouslySetInnerHTML={{ __html: ModalDescription }} />
                        </div>
                    </div>
                )
            }

        </div>
    );
});

export default Table;
