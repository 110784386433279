import React from 'react';
import { IoSearch } from "react-icons/io5";
import { FiDownload } from "react-icons/fi";
import { MdOutlineDateRange } from "react-icons/md";
import Table from '../tableUsers/Table';

const UserRequests = () => {
  return (
    <div className='UserRequests'>
      <div className="user-requests">
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <div className="search-box">
            <IoSearch />
            <input type="text" placeholder="Поиск" />
          </div>
          <div className="select-period">
            <input type="text" placeholder="Выберите период" />
            <div className="dropdown-icon"><MdOutlineDateRange /> </div>
          </div>
        </div>
        <button className="download-button">
          <span className="download-icon"><FiDownload /></span> Скачать
        </button>
      </div>
      <div className='table_users'>
        <Table />
      </div>
    </div>
  );
};

export default UserRequests;
